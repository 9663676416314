import './hodlogin.css';
import {useEffect  } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-regular-svg-icons'; 
import React, { useState } from 'react';
import axios from 'axios';
import { baseurl } from './Baseurl';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoginStatus } from './userdetails';
import Modal from './Modal';

const Hod = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userData = useSelector((state) => state.userDetails);
  const currentDate = new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const dispatch = useDispatch();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [closingcomplaints, setClosingcomplaints] = useState([]);
  const [Action, setAction] = useState('');
  const [Explanation, setExplanation] = useState('');
  const [errorstatus, setErrorstatus] = useState(false);

  const handleDateChange = (event) => {
   
    setSelectedDate(event.target.value);

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Action:', Action);
    console.log('Explanation:', Explanation);
   
    
      if(Action && Explanation)
      {
        
    let userData1 = new FormData()
    userData1.append('Action', Action)
    userData1.append('Explanation', Explanation)



    axios
      .post('', userData1, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      .then((response) => {
  
      

      })

      .catch((error) => {

        console.log(error)
        setErrorstatus(true)

      })
      
    }
   
}

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    dispatch(
      updateLoginStatus({
          isLoggedIn:true,
          Pagename: userData.Pagename, 
          Token:userData.Token,
          userId:userData.userId,
              
      })
    )

    console.log("Token",userData.Token)
    console.log("userId",userData.userId)
    console.log("date",selectedDate)
    axios({
          
          method: 'GET',
  
          url:baseurl +'/complaint/getDepartmentComplaint',
          params: {
            actionTakenDate: selectedDate,
            facultyId: userData.userId,
            token:userData.Token,
      
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          
          }

        })
  
          .then((response) => {
            const closedcomplaintlist = response.data
            console.log("action to be taken",closedcomplaintlist)
            setClosingcomplaints(closedcomplaintlist)
            
        
          })
          .catch((error) => {
           
           })
    }, [])

  return (
    <div className="containerh">
      
      <div className='staffheading'><center><h1>COMPLAINTS TO BE CLOSE</h1></center></div>
     
    <div class='row'>
  
  <div class="col-md-12 col-lg-12 col-xl-12">

  <div class='row'>
      <div class="col-md-6 col-lg-6 col-xl-6"></div>
      <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2">

        </div>
      <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2">
     <input type="date" className="date-input"  value={selectedDate}
      onChange={handleDateChange} />
      </div></div>
     
  <div className='tableContainercomplaintlisthod' >

    <div class='row'>
      
      <table
    
       readOnly="readOnly"
       className='comptablehod'
     > <thead>
       <tr class="trst3">
         
          <th>Complaint ID</th>
          <th>Student ID</th>
          <th>Student Name</th>
          <th>Department</th>
          <th>Year</th>          
          <th>Date</th>
          <th>view</th>
          <th>status</th>
         
        </tr>
        </thead>
        <tbody>
        {Array.isArray(closingcomplaints) && closingcomplaints.map((item1, index) => (
        <tr key={index}>
       
        <td><div className="content-div">{item1.complaintId}</div></td>
        <td><div className="content-div">{item1.rollno}</div></td>
        <td><div className="content-div">{item1.studentName}</div></td>
        <td><div className="content-div">{item1.department}</div></td>
        <td><div className="content-div">{item1.year}</div></td>
        <td><div className="content-div">{item1.date}</div></td>
       
        <td><div className="content-div"> 
        <div>
      
      <button onClick={openModal}><FontAwesomeIcon icon={faSquareCheck} /></button>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
       
      <div className='modaltext'>
      <div className='dateset'><input type="date" className="date-input"  value={selectedDate}
      onChange={handleDateChange} /></div> 
        <h2>Action </h2>
        {/* <h4>Action Taken</h4> <div className='boxhod'> suspended 2 days</div>
        <h4>Explanation</h4> <div className='boxhod'>Roaming outside</div> */}
         <form className="Modal-form" >
           <div className="form-group">
          <label htmlFor="Action">Action Taken :</label>
           <input
            type="text"
            id="Action"
            className="inputfield"
            value={Action}
           
           />
          </div>
          <div className="form-group">
          <label htmlFor="Explanation">Explanation:</label>
           <input
            type="text"
            id="Explanation"
            className="inputfield"
            value={Explanation}
            
           />
          </div>
       
      </form>
        <button  className='buttonmodal' onClick={closeModal} onSubmit={handleSubmit}>Ok</button> 
        </div>
        
      </Modal>
    </div>
        </div></td>
        <td><div className="content-div">{item1.status}</div></td>
     
       
        </tr>
            ))}
    </tbody>
    </table>
    </div>
    
    </div>
  </div>
  </div>
  
  
</div>
);
};

export default Hod;
