// // import React, { useState } from 'react';
// // import { Link } from 'react-router-dom';
// // // import LoginPage from './loginpage';
// // import '../stafflogin.css'; 
// // import axios from 'axios';
// // const EveningRoaming = () => {
// //   const [isMenu1Open, setIsMenu1Open] = useState(false);
// //   const [studentId, setStudentId] = useState('');
// //   const [successstatus, setsuccessstatus] = useState(false);
// //   const currentDate = new Date().toISOString().split('T')[0];
// //   const [selectedDate, setSelectedDate] = useState(currentDate);



// //   const handleDateChange = (event) => {
   
// //     setSelectedDate(event.target.value);

// //   };

// //   const toggleMenu1 = () => {
// //     setIsMenu1Open(!isMenu1Open);
// //   };
// //   const handleStudentIdChange = (event) => {
// //     setStudentId(event.target.value);
    
// //   };

// //   const handleSubmit = async (event) => {
// //     event.preventDefault();
// //     console.log(studentId)
// //     if(studentId)
// //       {
        
// //     let userData2 = new FormData()
// //     userData2.append('rollno', studentId)
// //     userData2.append('category',"late comer")
// //     userData2.append('date',selectedDate)




// //     axios
// //       .post('http://172.21.0.209:8080/addComplaint', userData2, {
// //         headers: {  'Content-Type': 'application/json' },
// //       })

// //       .then((response) => {
       
  
// //         console.log(response.data)

// //         const data = response.data

       
// //         setsuccessstatus(true)


// //       })

// //       .catch((error) => {

// //         console.log(error)


// //       })
      
// //     }
// // ////////////////get complaint
// //     if(successstatus === true)
// //       {
// //       //   axios({
          
// //       //     method: 'GET',
  
// //       //     url:'http://172.21.0.209:8080/addComplaint',
  
// //       //     headers: {
// //       //       'Content-Type': 'multipart/form-data',
          
// //       //     }

// //       //   })
  
// //       //     .then((response) => {
// //       //       const productionData2 = response.data
// //       //       setdispatchlist(productionData2)
            
// //       //     })
// //       //     .catch((error) => {
           
// //       //     })
// //       }
   

// //   }

// //   return (
// //     <div className="containerq">
// //       <div className='staffheading'><center><h1>EVENING ROAMING COMPLAINT REGISTRATION</h1></center></div>
// //       <input type="date" className="date-input"  value={selectedDate}
// //       onChange={handleDateChange} />
// //       <div className="main">
// //         <form onSubmit={handleSubmit}>
// //           <label htmlFor="studentId">Student ID:</label>
// //           <input type="text" id="studentId" name="studentId" value ={studentId} onChange={handleStudentIdChange} />
// //           <button  className = 'staffsubmit' type="submit">Submit</button>
// //           {successstatus && <div className='successcolor'>Complaint Registered successfully</div>}
// //         </form>
// //       </div>

         
// //       <div className='tableContainercomplaintlist' >
// //       <div className='table1bdstyle'>
    
// //         <div class='row'>
          
// //           <table
// //         className='comptable'
// //            readOnly="readOnly"
// //          >
// //            <tr class="trst1">
// //               <th>S.No</th>
// //               <th>Complaint ID</th>
// //               <th>Student ID</th>
// //               <th>Student Name</th>
// //               <th>Department</th>
// //               <th>Section</th>
// //               <th>Year</th>
// //               <th>Complaint Category</th>
// //               <th>Number of times complaint Registered</th>
             
// //             </tr>
// //             <tr>
// //             <td><div className="content-div">bgfhg</div></td>
// //             <td><div className="content-div">bgfhg</div></td>
// //             <td><div className="content-div">bgjh</div></td>
// //             <td><div className="content-div">jhj</div></td>
// //             <td><div className="content-div">jhghg</div></td>
// //             <td><div className="content-div">ghfgyf</div></td>
// //             <td><div className="content-div">ghfh</div></td>
// //             <td><div className="content-div">ghfh</div></td>
// //             <td><div className="content-div">ghfh</div></td>
         
           
// //             </tr>
         
// //         </table>
// //         </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default EveningRoaming;
// import React from 'react';
// import { useState,useEffect  } from 'react';
// import { Link } from 'react-router-dom';
// // import LoginPage from './loginpage';
// import '../stafflogin.css'; 
// import axios from 'axios';
// import { updateLoginStatus } from '../userdetails';
// import { useDispatch, useSelector } from 'react-redux';

// const EveningRoaming = () => {
//   const [isMenu1Open, setIsMenu1Open] = useState(false);
//   const [studentId, setStudentId] = useState('');
//   const [successstatus, setsuccessstatus] = useState(false);
//   const currentDate = new Date().toISOString().split('T')[0];
//   const [selectedDate, setSelectedDate] = useState(currentDate);
//   const [raisedComplaint, setRaisedcomplaint] = useState('');

//   const userData = useSelector((state) => state.userDetails);
//   const dispatch = useDispatch();

//   const handleDateChange = (event) => {
   
//     setSelectedDate(event.target.value);

//   };

//   const toggleMenu1 = () => {
//     setIsMenu1Open(!isMenu1Open);
//   };
//   const handleStudentIdChange = (event) => {
//     const trimmedValue = event.target.value.trim();
//     setStudentId(trimmedValue);
    
//   };
//   useEffect(() => {
//     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

//     dispatch(
//       updateLoginStatus({
//           isLoggedIn:true,
//           Pagename: userData.Pagename, 
//           Token:userData.Token,
//           userId:userData.userId,
              
//       })
//     )
  
// }, [])

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     console.log("student's rollno",studentId)
//     if(studentId)
//       {
        
//     let userData2 = new FormData()
//     userData2.append('rollno', studentId)
//     userData2.append('category',"Evening Roaming")
//     userData2.append('date',selectedDate)
//     userData2.append('facultyid', "fac001")




//     axios
//       .post('http://172.21.0.209:8080/addComplaint', userData2, {
       
//         params: {
         
//           facultyId: userData.userId,
//           token:userData.Token,
    
//         }, headers: {  'Content-Type': 'application/json' },
//       })

//       .then((response) => {
       
  
//         console.log(response.data)

//         const data = response.data

       
//         setsuccessstatus(true)
//     //     let userData1a = new FormData()
//     //  userData1a.append('rollno', studentId)
//     //  userData1a.append('facultyid',"fac001")
//     //  console.log(studentId);
        
//     //   axios
//     //   .get('http://172.21.0.209:8080/getComplaintByFacultyId', userData1a, {
//     //     headers: {  'Content-Type': 'application/json' },
//     //     params:{
//     //       rollno : studentId,
//     //       facultyid : 'fac001'
//     //     }
//     //   })

//     const studentid = studentId; // replace with actual student ID
// const facultyId = 'fac001'; // replace with actual faculty ID

// // Axios GET request
// axios
//   .get('http://172.21.0.209:8080/getComplaintByFacultyId', {
//     params: {
//       rollno: studentid,
//       facultyid: facultyId,
//     },
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   })

  
//           .then((response) => {
          
//             // setdispatchlist(productionData2)
//             // setRaisedcomplaint(response.data)
//             console.log( response.data)
//             const comp = response.data
//             console.log("complaint",comp)
//             const complaintsArray = Array.isArray(comp) ? comp : [comp];
            
//             setRaisedcomplaint((prevComplaints) => [...prevComplaints, ...complaintsArray]);
// setStudentId(" ")
//             setsuccessstatus(false)
            
//           })
//           .catch((error) => {
           
//           })


//       })

//       .catch((error) => {

//         console.log(error)


//       })
      
//     }
// ////////////////get complaint
// // console.log(Array.isArray(raisedcomplaint));

   

//   }

//   return (
//     <div className="containerq">
//       <div className='staffheading'><center><h1>EVENING ROAMING COMPLAINT REGISTRATION</h1></center></div>
    
//       <input type="date" className="date-input"  value={selectedDate}
//       onChange={handleDateChange} />
//         <div class='row'>
//       <div class="col-md-4 col-lg-4 col-xl-4"></div>
//       <div class="col-md-6 col-lg-6 col-xl-6">
//       <div className="main">
//         <form onSubmit={handleSubmit}>
//           <label htmlFor="studentId">Student ID:</label>
//           <input type="text" id="studentId" name="studentId" value ={studentId} onChange={handleStudentIdChange} />
//           <button  className = 'staffsubmit' type="submit">Submit</button>
//           {successstatus && <div className='successcolor'>Complaint Registered successfully</div>}
//         </form>
//       </div>

         
//       <div className='tableContainercomplaintlist' >

//         <div class='row'>
          
//         <table className="comptable">
//   <thead>
//     <tr className="trst1">
//       <th>S.No</th>
//       <th>Complaint ID</th>
//       <th>Student ID</th>
//       <th>Student Name</th>
//       <th>Department</th>
//       <th>Section</th>
//       <th>Year</th>
//       <th>Complaint Category</th>
//       <th>Number of times complaint Registered</th>
//     </tr>
//   </thead>
//   <tbody>
//     {Array.isArray(raisedComplaint) && raisedComplaint.map((item1, index) => (
//       <tr key={index}>
//         <td><div className="content-div">{index + 1}</div></td>
//         <td><div className="content-div">{item1.ComplaintId}</div></td>
//         <td><div className="content-div">{item1.studentId}</div></td>
//         <td><div className="content-div">{item1.studentName}</div></td>
//         <td><div className="content-div">{item1.department}</div></td>
//         <td><div className="content-div">{item1.section}</div></td>
//         <td><div className="content-div">{item1.year}</div></td>
//         <td><div className="content-div">{item1.ComplaintCategory}</div></td>
//         <td><div className="content-div">{item1.complaintCount}</div></td>
//       </tr>
//     ))}
//   </tbody>
// </table>
//         </div>
        
//         </div>
//       </div>
//       </div>
//     </div>
//   );
// };

// export default EveningRoaming;


import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useState,useEffect  } from 'react';
import { Link } from 'react-router-dom';
import { updateLoginStatus } from '../userdetails';
import { useDispatch, useSelector } from 'react-redux';
import LoginPage from '../loginpage';
import '../stafflogin.css'; 
import axios from 'axios';
import { baseurl } from '../Baseurl';

const EveningRoaming = () => {
  const [isMenu1Open, setIsMenu1Open] = useState(false);
  const [studentId, setStudentId] = useState('');
  const [successstatus, setsuccessstatus] = useState(false);
  const currentDate = new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [raisedComplaint, setRaisedcomplaint] = useState('');

  const userData = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();

  const handleDateChange = (event) => {
   
    setSelectedDate(event.target.value);

  };

  const toggleMenu1 = () => {
    setIsMenu1Open(!isMenu1Open);
  };
  const handleStudentIdChange = (event) => {
    const trimmedValue = event.target.value.trim();
    setStudentId(trimmedValue);
    
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    dispatch(
      updateLoginStatus({
          isLoggedIn:true,
          Pagename: userData.Pagename, 
          Token:userData.Token,
          userId:userData.userId,
              
      })
    )
  
}, [])

  const handleSubmit = async (event) => {
 
  
    event.preventDefault();
    console.log("student's rollno",studentId)
    if(studentId)
      {
        console.log("faculty",userData.userId)
    let userData2 = new FormData()
    userData2.append('rollno', studentId)
    userData2.append('category',"Evening Roaming")
    userData2.append('date',selectedDate)
   



    axios
      .post(baseurl +'/complaint/addComplaint', userData2,  {
        params: {
         
          facultyId: userData.userId,
          token:userData.Token,
    
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      .then((response) => {
       
  
        console.log(response.data)

        const data = response.data

       
        setsuccessstatus(true)
    //     let userData1a = new FormData()
    //  userData1a.append('rollno', studentId)
    //  userData1a.append('facultyid',"fac001")
    //  console.log(studentId);
        
    //   axios
    //   .get('http://172.21.0.209:8080/getComplaintByFacultyId', userData1a, {
    //     headers: {  'Content-Type': 'application/json' },
    //     params:{
    //       rollno : studentId,
    //       facultyid : 'fac001'
    //     }
    //   })

    const studentid = studentId; // replace with actual student ID
const facultyId = 'fac001'; // replace with actual faculty ID

// Axios GET request
axios
  .get(baseurl +'/complaint/getComplaintByFacultyId', {
    params: {
      rollno: studentid,
      facultyid: userData.userId,
      token:userData.Token,

    },
    headers: {
      'Content-Type': 'application/json',
    },
  })

  
          .then((response) => {
          
            // setdispatchlist(productionData2)
            // setRaisedcomplaint(response.data)
            console.log( response.data)
            const comp = response.data
            console.log("complaint",comp)
            const complaintsArray = Array.isArray(comp) ? comp : [comp];
            
            setRaisedcomplaint((prevComplaints) => [...prevComplaints, ...complaintsArray]);
setStudentId(" ")
            setsuccessstatus(false)
            
          })
          .catch((error) => {
           
          })


      })

      .catch((error) => {

        console.log(error)


      })
      
    }
////////////////get complaint
// console.log(Array.isArray(raisedcomplaint));

   

  }

  return (
    <div className="containerst">
      <div className='staffheading'><center><h1>EVENING ROAMING COMPLAINT REGISTRATION</h1></center></div>
      <div class='row'>
      <div class="col-md-8 col-lg-8 col-xl-8"></div>
      <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2">
     <input type="date" className="date-input"  value={selectedDate}
      onChange={handleDateChange} disabled/>
      </div></div>
        <div class='row'>
      <div class="col-md-4 col-lg-4 col-xl-4"></div>
      <div class="col-md-10 col-lg-10 col-xl-10 col-sm-12 col-12">
      <div className="main">
        <form onSubmit={handleSubmit}>
          <label htmlFor="studentId">Student ID:</label>
          <input type="text" id="studentId" name="studentId" value ={studentId} onChange={handleStudentIdChange} />
          <button  className = 'staffsubmit' type="submit">Submit</button>
          {successstatus && <div className='successcolor'>Complaint Registered successfully</div>}
        </form>
      </div>

         
      <div className='tableContainercomplaintlist' >

        <div class='row'>
          
        <table className="comptable">
  <thead>
    <tr className="trst1">
      <th>S.No</th>
      <th>Complaint ID</th>
      <th>Student ID</th>
      <th>Student Name</th>
      <th>Department</th>
      <th>Year</th>
      <th>Complaint Category</th>
      <th>Number of times complaint Registered</th>
    </tr>
  </thead>
  <tbody>
    {Array.isArray(raisedComplaint) && raisedComplaint.map((item1, index) => (
      <tr key={index}>
        <td><div className="content-div">{index + 1}</div></td>
        <td><div className="content-div">{item1.ComplaintId}</div></td>
        <td><div className="content-div">{item1.studentId}</div></td>
        <td><div className="content-div">{item1.studentName}</div></td>
        <td><div className="content-div">{item1.department}</div></td>
        <td><div className="content-div">{item1.year}</div></td>
        <td><div className="content-div">{item1.ComplaintCategory}</div></td>
        <td><div className="content-div">{item1.complaintCount}</div></td>
      </tr>
    ))}
  </tbody>
</table>
        </div>
        
        </div>
      </div>
      </div>
    </div>
  );
};

export default EveningRoaming;
