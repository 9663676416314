import React, { useState, useEffect } from 'react';
import './LoginPage.css';
import axios from 'axios';
import { baseurl } from './Baseurl';
import ButtonLink from './buttonlink';
const Loginform = () => {
    
    const [usernameValid, setusernameValid] = useState(false)
    const [usernameerror, setusernameerror] = useState('')
    const [passwordValid, setPasswordValid] = useState(false)
    const [passworderror, setPassworderror] = useState('')
   
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [successstatus, setSuccessstatus] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [errorstatus, setErrorstatus] = useState(false);
    const [facultyerror, setFacultyerror] = useState(false);
  
    const handleUsernameChange = (event) => {
      setUsername(event.target.value);
    };
    const handleconfirmpasswordChange = (event) => {
      setconfirmPassword(event.target.value);
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      console.log('username:', username);
      console.log('password:', password);
      setErrorstatus(false)
      setFacultyerror(false)
      if (password !== confirmPassword) {
        setError('Passwords does not match');
          setErrorstatus(true)
        return;
      }
  
      // try {
      //   const response = await axios.post('http://172.21.0.209:8080/staff/addstaff', {
      //     username,
      //     password,
      //   });
  
      //   console.log('Reset password success:', response.data);
      //   setSuccessMessage('Password reset successful!');
      //   // Clear form fields after successful submission
      //   setUsername('');
      //   setPassword('');
      //   setconfirmPassword('');
      //   setError(null);
      // } catch (error) {
      //   console.error('Error resetting password:', error);
      //   setError('Failed to reset password. Please try again later.');
      // }
      else{
        if(username && password)
        {
          
      let userData = new FormData()
      userData.append('facultyId', username)
      userData.append('password', password)
      userData.append('confirmPassword', confirmPassword)


      axios
        .post(baseurl +'/Staff/Register', userData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        .then((response) => {
         
    
          console.log(response.data)

          const data = response.data

         
          setSuccessstatus(true)

        })

        .catch((error) => {

          console.log(error)
          setFacultyerror(true)
         


        })
        }
      }
     
  }
    return (
      <div className="login-page">
     {successstatus && <ButtonLink btnPath="/" />}
         {/* <div class="logo">
         <img  src={logo}  alt="Logo" style={{ width: 690, height: 90 }} />
         </div>
         */}
        <form className="login-form" onSubmit={handleSubmit}>
          <h1>Register Here</h1>
         <h2>SignUp</h2>
          <div className="form-group " >
  
            <label htmlFor="username">Username:</label>
            <input
              
              type="text"
              id="username"
              className="inputfield"
              value={username}
              onChange={ handleUsernameChange }
            //  //required
            />
            
          </div>
          <div className="form-group ">
            <label htmlFor="password">Password:</label>
            <input
            className="inputfield"
           
              type="password"
              id="password"
              maxLen={16}
              value={password}
              onChange={handlePasswordChange}
            //   //required
            />
            
          </div>
          <div className="form-group ">
            <label htmlFor="password"> Confirm Password:</label>
            <input
            className="inputfield"
            
              type="password"
              id="confirmpassword"
              maxLen={16}
              value={confirmPassword}
              onChange={handleconfirmpasswordChange}
            />
            
          </div>
          <button type="submit" > SignUp</button> 

          {successstatus&&<div className='successcolor'>Registered successfully</div>}
          {facultyerror && <div className="wrongmsg">FacultyId does not match</div>}
          {errorstatus && <div className="wrongmsg">Invalid username or password</div>}
            
        
      
       </form>
        
      </div>
    );
  };
  
  export default Loginform;
  