// // import React, { useState } from 'react';
// // import './studenthistory.css'; 

// // const History = () => {
 
// //   return (
// //     <div className="container">
// //       <input type="date" className="date-input" />
// //       <div className="main">
// //         <form>
// //           <label htmlFor="studentId">Student ID:</label>
// //           <input type="text" id="studentId" name="studentId" />
// //           <div className='hissubmit'> <button type="submit">Submit</button></div>
         
// //         </form>
// //       </div>
// //       <div className="table-container">
// //         <table className="info-table">
// //           <tbody>
// //             <tr className='trsth'>
// //               <th><h4>Complaint ID</h4></th>
// //               <th><h4>Student ID</h4></th>
// //               <th><h4>Student Name</h4></th>
// //               <th><h4>Department</h4></th>
// //               <th><h4>Section</h4></th>
// //               <th><h4>Year</h4></th>
// //               <th><h4>Date</h4></th>
             
// //             </tr>
// //             <tr>
// //             <td><div className="content-div"></div></td>
// //             <td><div className="content-div"></div></td>
// //             <td><div className="content-div"></div></td>
// //             <td><div className="content-div"></div></td>
// //             <td><div className="content-div"></div></td>
// //             <td><div className="content-div"></div></td>
// //             <td><div className="content-div"></div></td>
// //             </tr>
// //           </tbody>
// //         </table>
// //       </div>
// //       <div className='history'>
// //         <h3>number of Complaint</h3> 
// //         <div className='numh'>101</div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default History;
// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { useState,useEffect  } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import './studenthistory.css'; 


// const History = () => {
//   const [studentId, setStudentId] = useState('');
//   const currentDate = new Date().toISOString().split('T')[0];
//   const [selectedDate, setSelectedDate] = useState(currentDate);
  

//   const handleDateChange = (event) => {
   
//     setSelectedDate(event.target.value);




//   };

  
//   const handleSubmit = async (event) => {
 
//   }

//   return (
//     <div className="containerst">
     
//       <div class='row'>
//       <div class="col-md-8 col-lg-8 col-xl-8"></div>
//       <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2">
//      <input type="date" className="date-input"  value={selectedDate}
//       onChange={handleDateChange} />
//       </div></div>
//         <div class='row'>
//       <div class="col-md-4 col-lg-4 col-xl-4"></div>
//       <div class="col-md-10 col-lg-10 col-xl-10 col-sm-12 col-12">
//       <div className="main">
//         <form onSubmit={handleSubmit}>
//           <label htmlFor="studentId">Student ID:</label>
//           <input type="text" id="studentId" name="studentId" value ={studentId}/>
//           <button  className = 'staffsubmit' type="submit">Submit</button> 
//         </form>
//       </div>

         
//       <div className='tableContainercomplaintlist' >

//         <div class='row'>
          
//         <table className="comptable">
//   <thead>
//     <tr className="trst1">
     
//       <th>Complaint ID</th>
//       <th>Student ID</th>
//       <th>Student Name</th>
//       <th>Department</th>
//       <th>Section</th>
//       <th>Year</th>
//       <th>Date</th>
      
//     </tr>
//   </thead>
//   <tbody>
   
//       <tr>
//         <td><div className="content-div"></div></td>
//         <td><div className="content-div"></div></td>
//         <td><div className="content-div"></div></td>
//         <td><div className="content-div"></div></td>
//         <td><div className="content-div"></div></td>
//         <td><div className="content-div"></div></td>
//         <td><div className="content-div"></div></td>
        
//       </tr>
    
//   </tbody>
// </table>
//         </div>
        
//         </div>
//       </div>
//       </div>
//       <div className='History'>
//       <h3>number of Complaint</h3> 
//       <div className='num'></div>
//       </div>
//     </div>
//   );
// };

// export default History;

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './studenthistory.css'; 

const History = () => {
  const [studentId, setStudentId] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    complaintId: '',
    studentId: '',
    studentName: '',
    department: '',
    section: '',
    year: '',
    date: ''
  });

  useEffect(() => {
    if (showForm) {
      // Fetch data from backend when showForm is true
      const fetchData = async () => {
        try {
         
          const response = await fetch(`https://your-backend-api.com/complaint?studentId=${studentId}&date=${selectedDate}`);
          const data = await response.json();
          setFormData({
            complaintId: data.complaintId || '',
            studentId: data.studentId || '',
            studentName: data.studentName || '',
            department: data.department || '',
            section: data.section || '',
            year: data.year || '',
            date: data.date || ''
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [showForm, studentId, selectedDate]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleStudentIdChange = (event) => {
    setStudentId(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowForm(true); 
  };

  return (
    <div className="containerst">
      <div className="row">
        <div className="col-md-8 col-lg-8 col-xl-8"></div>
        <div className="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2">
          <input 
            type="date" 
            className="date-input"  
            value={selectedDate}
            onChange={handleDateChange} 
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-lg-4 col-xl-4"></div>
        <div className="col-md-10 col-lg-10 col-xl-10 col-sm-12 col-12">
          <div className="main">
            <form onSubmit={handleSubmit}>
              <label htmlFor="studentId">Student ID:</label>
              <input 
                type="text" 
                id="studentId" 
                name="studentId" 
                value={studentId}
                onChange={handleStudentIdChange}
              />
              <button className="staffsubmit" type="submit">Submit</button> 
            </form>
          </div>
          {showForm && (
            <div className="formContainer">
              <form>
                <div className="form-group">
                  <label htmlFor="complaintId">Complaint ID:</label>
                  <input 
                    type="text" 
                    id="complaintId" 
                    name="complaintId"
                    value={formData.complaintId}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="studentId">Student ID:</label>
                  <input 
                    type="text" 
                    id="studentId" 
                    name="studentId"
                    value={formData.studentId}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="studentName">Student Name:</label>
                  <input 
                    type="text" 
                    id="studentName" 
                    name="studentName"
                    value={formData.studentName}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="department">Department:</label>
                  <input 
                    type="text" 
                    id="department" 
                    name="department"
                    value={formData.department}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="section">Section:</label>
                  <input 
                    type="text" 
                    id="section" 
                    name="section"
                    value={formData.section}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="year">Year:</label>
                  <input 
                    type="text" 
                    id="year" 
                    name="year"
                    value={formData.year}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="date">Date:</label>
                  <input 
                    type="date" 
                    id="date" 
                    name="date"
                    value={formData.date}
                    readOnly
                  />
                </div>
                <div className="History">
                       <h3>Number of Complaints</h3> 
                      <div className="num">101</div>
                </div> 
              </form>
            </div>
          )}
        </div>
      </div>
     
    </div>
  );
};

export default History;
