// import { combineReducers } from 'redux'
// const UPDATE_LOGIN_STATUS = 'UPDATE_LOGIN_STATUS'

// export function updateLoginStatus(userData) {
//   return {
//     type: UPDATE_LOGIN_STATUS,
//     payload: userData
//   }
// }


// const defaultUserData = {
//   isLoggedIn: false,
//   Pagename:'stafflogin',
  

// }

// function userDetails(state = defaultUserData, action) {
//   switch (action.type) {
//     case UPDATE_LOGIN_STATUS:
//       return {
//         ...state,
//         isLoggedIn: action.userData.isLoggedIn,
//         Pagename:action.userData.Pagename,
//       }      
       
//     default:
//         return state;
// }
// }

// const userDataStore = combineReducers({
//   userDetails,
// })

// export default userDataStore


import { combineReducers } from 'redux';

const UPDATE_LOGIN_STATUS = 'UPDATE_LOGIN_STATUS';

export function updateLoginStatus(userData) {
  return {
    type: UPDATE_LOGIN_STATUS,
    payload: userData,
  };
}

const defaultUserData = {
  isLoggedIn: false,
  Pagename:'',
  Token:'',
  userId:'',
};

function userDetails(state = defaultUserData, action) {
  switch (action.type) {
    case UPDATE_LOGIN_STATUS:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        Pagename: action.payload.Pagename,
        Token:action.payload.Token,
        userId:action.payload.userId,
      };
    default:
      return state;
  }
}

const userDataStore = combineReducers({
  userDetails,
});

export default userDataStore;
