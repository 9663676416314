import React from 'react';
import { useState,useEffect  } from 'react';
import './actiontaken.css'; 
import axios from 'axios';
import { baseurl } from './Baseurl';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoginStatus } from './userdetails';

const Action = () => {
  const [studentId, setStudentId] = useState('');
  const userData = useSelector((state) => state.userDetails);
  const currentDate = new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [complaintlist, setComplaintlist] = useState([]);
  
  const dispatch = useDispatch();
  const handleDateChange = (event) => {
   
    setSelectedDate(event.target.value);

  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    dispatch(
      updateLoginStatus({
          isLoggedIn:true,
          Pagename: userData.Pagename, 
          Token:userData.Token,
          userId:userData.userId,
              
      })
    )
    const studentid = studentId;
    console.log("Token",userData.Token)
    console.log("userId",userData.userId)
    console.log("date",selectedDate)
    axios({
          
          method: 'GET',
  
          url:baseurl +'/history/getcomplainthistory',
          params: {
            actionTakenDate: selectedDate,
            facultyId: userData.userId,
            token:userData.Token,
      
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          
          }

        })
  
          .then((response) => {
            const closedcomplaintlist = response.data
            console.log("action taken complaints",closedcomplaintlist)
            setComplaintlist(closedcomplaintlist)
        
          })
          .catch((error) => {
           
           })
    }, [])

  return (
    <div className="containerq">
          <div className='staffheading'><center><h1>ACTION TAKEN COMPLAINTS</h1></center></div>
          <div class='row'>
      <div class="col-md-8 col-lg-8 col-xl-8"></div>
      <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2">
     <input type="date" className="date-input"  value={selectedDate}
      onChange={handleDateChange} />
      </div></div>
      <div className="table-container">
        <table className="info-table">
          <tbody>
          <tr class="trst1">
              <th><h4>S.No</h4></th>
              <th><h4>Complaint ID</h4></th>
              <th><h4>Student ID</h4></th>
              <th><h4>Student Name</h4></th>
              <th><h4>Department</h4></th>
              <th><h4>Year</h4></th>
              <th><h4>Section</h4></th>
              <th><h4>Category</h4></th>
              <th><h4>Action Taken</h4></th>
              <th><h4>Date</h4></th>
              <th><h4>Select</h4></th>
            </tr>
            {complaintlist.map((item, index) => {

return (
      <tr key={index}>
         <td><div className="content-div">{index - 1}</div></td>
            <td><div className="content-div">{item.complaintId}</div></td>
            <td><div className="content-div">{item.studentId}</div></td>
            <td><div className="content-div">{item.studentName}</div></td>
            <td><div className="content-div">{item.department}</div></td>
            <td><div className="content-div">{item.year}</div></td>
            <td><div className="content-div">{item.section}</div></td>
            <td><div className="content-div">{item.category}</div></td>
            <td><div className="content-div">{item.actionTaken}</div></td>
            <td><div className="content-div">{item.date}</div></td>
            <td><div className="content-div">{item.date}</div></td>
            </tr>
)})}
          </tbody>
        </table>
      </div>
      <div >
        {/* <center>
        <button type='submit' className='buttons1a'>select all</button> 
        <button type='submit' className='buttons1a'>select</button>
        <button type='submit' className='buttons1a'>clear</button>
        </center> */}
     </div>
    </div>
  )
};

export default Action;
