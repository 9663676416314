import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl } from './Baseurl';
import './viewcomplaint.css'; 
import { updateLoginStatus } from './userdetails';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../images/logo.jpg';


const Viewcomplaint = () => {
  const [isMenu1Open, setIsMenu1Open] = useState(false);
  const [studentId, setStudentId] = useState('');
  const [successstatus, setSuccessStatus] = useState(false);
  const currentDate = new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [latecount, setLatecount] = useState('');
  const [roamingEvening, setRoamingEvening] = useState('');
  const [roamingNoon, setRoamingNoon] = useState('');
  const [complist, setComplist] = useState([]);
  const [selectedComplaints, setSelectedComplaints] = useState({});
  const [selectedValue, setSelectedValue] = useState("");

  const userData = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [ComplaintId, setComplaintId] = useState([]);
  
  const handleSelectall = () => {
    if (complist.length === Object.keys(selectedComplaints).length) {
      // If all items are already selected, deselect all
      setSelectedComplaints({});
    } else {
      // Otherwise, select all items
      const newSelection = complist.reduce((acc, item) => {
        acc[item.complaintId] = true;
        return acc;
      }, {});
      setSelectedComplaints(newSelection);
    }
  };

  
  // Fetch complaints data
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    console.log(value)
  }
  const handleClick = (param) => {
    axios
    .get(baseurl +'/complaint/getComplaintCountByDD', {
      params: {
        date: selectedDate,
        department: selectedValue,
        facultyId: userData.userId,
        token:userData.Token,
        
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      const compno1 = response.data;
      console.log("useeffect complaint count",compno1)
      // setLatecount(compno.laterComerCount);
      // setRoamingEvening(compno.roamingEveningCount);
      // setRoamingNoon(compno.roamingCampusCount);
      //  if (Array.isArray(compno) && compno.length > 0) {
      //     const counts = compno[compno.length - 1]; // Adjust if counts are not at the end
      //     setLatecount(counts.latecomerCount || 0);
      //     setRoamingEvening(counts.eveningRoamingCount || 0);
      //     setRoamingNoon(counts.roamingCampusCount || 0);}
      setLatecount(compno1.laterComerCount);
        setRoamingEvening(compno1.roamingEveningCount);
        setRoamingNoon(compno1.roamingCampusCount);
    })
    .catch((error) => {
      console.error(error);
    });

    setSelectedCategory(param);
    console.log('Selected Date:', selectedDate);
    console.log('Selected Value:', selectedValue);
    console.log('Category Param:', param);
    
    axios
      .get(baseurl +'/complaint/getComplaintsByCDD', {
        params: {
          date: selectedDate,
          category: param,
          department: selectedValue,
          facultyId: userData.userId,
          token:userData.Token,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const list = response.data;
     
        const complaintsArray1 = Array.isArray(list) ? list : [list];
        setComplist(complaintsArray1);
        console.log("daily count",complaintsArray1)
        const compno = response.data;
        console.log('API Response:', compno);
        // if (Array.isArray(compno) && compno.length > 0) {
        //   const counts = compno[compno.length - 1]; // Adjust if counts are not at the end
        //   setLatecount(counts.latecomerCount || 0);
        //   setRoamingEvening(counts.eveningRoamingCount || 0);
        //   setRoamingNoon(counts.roamingCampusCount || 0);
          
        // }
        
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Fetch daily counts
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(
      updateLoginStatus({
          isLoggedIn:true,
          Pagename: userData.Pagename, 
          Token:userData.Token,
          userId:userData.userId,
              
      })
    )

    axios
      .get(baseurl +'/complaint/dailyCount', {
        params: {
          date: selectedDate,
          // department: selectedValue,
          facultyId: userData.userId,
          token:userData.Token,
          
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const compno = response.data;
        console.log("useeffect complaint count for overall",compno)
        setLatecount(compno.laterComerCount);
        setRoamingEvening(compno.roamingEveningCount);
        setRoamingNoon(compno.roamingCampusCount);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [selectedDate]);

  const handleSelect = (complaintId) => {
    setSelectedComplaints((prevSelected) => ({
      ...prevSelected,
      [complaintId]: !prevSelected[complaintId]
    }));
  };

  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    const selectedData = complist.filter(item => selectedComplaints[item.complaintId]);
    const printContent = `
      <html>
        <head>
          <title>Print Selected Complaints</title>
          <style>
            body { font-family: Arial, sans-serif; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ddd; padding: 8px; }
            th { background-color: #f4f4f4; }
            .container { margin: 20px; }
             img { max-width: 100%; height: auto; }
          </style>
        </head>
        <body>
          <div class="container">
           <center><img src="${logo}" alt="Logo" /></center>
            
            <h1>${selectedCategory}</h1>
            <table>
              <thead>
                <tr>
                 
                  <th>Complaint ID</th>
                  <th>Student ID</th>
                  <th>Student Name</th>
                  <th>Department</th>
                  <th>Year</th>
                  <th>Date</th>
                  
                </tr>
              </thead>
              <tbody>
                ${selectedData.map(item => `
                  <tr>
                    <td>${item.complaintId}</td>
                    <td>${item.studentId}</td>
                    <td>${item.studentName}</td>
                    <td>${item.department}</td>
                    <td>${item.year}</td>
                    <td>${selectedDate}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          </div>
        </body>
      </html>
    `;
    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };
  const handleForward = () => {
    // Gather selected complaints
    const selectedComplaintsList = complist.filter(item => selectedComplaints[item.complaintId]);
  
    // Check if any complaints are selected
    if (selectedComplaintsList.length === 0) {
      alert('No complaints selected to forward.');
      return;
    }
  console.log("selected complaits",selectedComplaintsList)
    // Prepare data to send
    // const complaintsToSend = selectedComplaintsList.map(item => ({
    //   complaintId: item.complaintId,
    //   studentId: item.studentId,
    //   studentName: item.studentName,
    //   department: item.department,
    //   year: item.year,
    //   date: item.date,
      
    // }));
    // console.log('Sending data:', complaintsToSend); // Log the data being sent
    
    // Make the API call to forward complaints
    axios.post(baseurl +"/complaint/selectComplaints/"+selectedComplaintsList,
       // Send data in the request body
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      // Handle success
      console.log('Complaints forwarded successfully:', response.data);
      alert('Complaints forwarded to HOD successfully.');
    })
    .catch(error => {
      // Handle error
      console.error('Error forwarding complaints:', error.response || error);
      alert('Failed to forward complaints. Please try again later.');
    });
  };
  
  
  
  return (
    <div className="containerc">
      <div className="row">
  
      <div class="col-md-8 col-lg-8 col-xl-8"></div>
      <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2">
        <input
          type="date"
          className="date-input"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
        </div>
        <div class="col-md-4 col-lg-4 col-xl-4"></div>
      <div class="col-md-10 col-lg-10 col-xl-10 col-sm-12 col-12">
          <div className="convinermain">
            <center>
              <div className="conbutton1">
              <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-2">
                <button className="consubmit1" onClick={() => handleClick('Late comer')}>
                  Gate complaint morning
                  <div className="num">{latecount}</div>
                </button>
                <button className="consubmit1" onClick={() => handleClick('Evening roaming')}>
                  Gate complaint evening
                  <div className="num">{roamingEvening}</div>
                </button>
                <button className="consubmit1" onClick={() => handleClick('Roaming Campus')}>
                  Campus roaming arround
                  <div className="num">{roamingNoon}</div>
                </button>
                
               </div> 
              </div>
            </center>
          </div>
        <center>
        <div className="dropdown">
            {/* <button className="dropdown-button">Department</button>
            <div className="dropdown-content ">
              <a value = "CSE">CSE</a>
              <a value = "IT">IT</a>
              <a value = "ECE">ECE</a>
              <a value = "EEE">EEE</a>
              <a value = "CSBS">CSBS</a>
              <a value = "IOT">IoT</a>
              <a value = "AIML">AIML</a>
              <a value = "AIDS">AIDS</a>
              <a value = "BME">BME</a>
            </div> */}
            <select id="dropdown" value={selectedValue} onChange={handleChange}>
        <option value="">Select...</option>
        <option value="B.E CSE">CSE</option>
        <option value = "B.Tech IT">IT</option>
        <option value = "B.E CD">CD</option>
        <option value = "B.E EEE">EEE</option>
        <option value = "B.E ECE">ECE</option>
        <option value = "B.TECH.CB">CSBS</option>
        <option value = "B.E CI">IoT</option>
        <option  value = "B.E AM">AIML</option>
        <option value = "B.Tech AI">AIDS</option>
        <option value = "B.E BM">BME</option>
        <option value = "B.Tech BT">Biotech</option>
        <option value = "B.E CIVIL">Civil</option>
        <option value = "B.Tech CHE">Chemical</option>
        <option value = "B.E MECH">Mechanical</option>
      </select>
          </div>
        </center>
          <div className="tableContainercomplaintlistconviner">
            <div className="row">
              <table className="comptableconviner">
                <thead>
                  <tr className="trst2">
                  <th>SNO:</th>
                    <th>Complaint ID</th>
                    <th>Student ID</th>
                    <th>Student Name</th>
                    <th>Department</th>
                    <th>Year</th>
                    <th>Date</th>
                    <th>Select</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(complist) && complist.map((item, key) => (
                    <tr key={item-1}>
                      <td><div className="content-div">{key}</div></td>
                      <td><div className="content-div">{item.complaintId}</div></td>
                      <td><div className="content-div">{item.studentId}</div></td>
                      <td><div className="content-div">{item.studentName}</div></td>
                      <td><div className="content-div">{item.department}</div></td>
                      <td><div className="content-div">{item.year}</div></td>
                      <td><div className="content-div">{selectedDate}</div></td>
                      <td>
                        <input
                          type="checkbox"
                          checked={!!selectedComplaints[item.complaintId]}
                          onChange={() => handleSelect(item.complaintId)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <center>
        <div className="conbutton2">
          
          <button className="consubmit"type="submit" onClick={ handleSelectall}>Select All</button>
          <button className="consubmit" type="submit"  onClick={() => handleForward('')}>Forward to Hod</button>
        </div>
      </center>
      <div className="print">
        <center>
        <button type="submit" onClick={() => handlePrint('')}>Print Selected </button>
        
        </center>
      </div>
    </div>
  );
};

export default Viewcomplaint;
