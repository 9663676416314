// import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { updateLoginStatus } from './userdetails';
// import logout from '../images/logout.png';
// import './AfterLoginheader.css';


// export default function Header() {
//   const userData = useSelector((state) => state.userDetails);
//   const dispatch = useDispatch();

//   // Update login status if Pagename is undefined
//   useEffect(() => {
   
//       dispatch(
//         updateLoginStatus({
//           isLoggedIn: true,
//          // Pagename: 'Staff_Login',
//         })
//       );
    
//   }, []);

//   return (
//     <div className="headersizediv">
//       <div className='headercolor'>
//         <nav className="navbar navbar-expand-xl navbar-light navstyle">
//           <div className="container-fluid">
//             <div className="logo-inside">
//               {/* Content for logo-inside */}
//             </div>
            
//             {/* <button
//               className="navbar-toggler expandbtn"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#navbarSupportedContent"
//               aria-controls="navbarSupportedContent"
//               aria-expanded="false"
//               aria-label="Toggle navigation"
//             >
//               <span className="navbar-toggler-icon"></span>
//             </button> */}

//             <div className="collapse navbar-collapse" id="navbarSupportedContent">
//               <ul className="navbar-nav ml-auto navbarsupport">
//                 <li className='Dashboardpad'>
//                   <button className='btn-style' disabled>
//                     <b>Stafflogin</b>
//                   </button>
//                 </li>
//                 <li className='padlside'>
//                   <div className='namest'>
//                     <b>&nbsp;{userData.name}</b>
//                     <br />
//                     <span>{userData.role}</span>
//                   </div>
//                 </li>
//                 <li className='nav-link'>
//                   <a href="/">
//                     <img src={logout} className='icon1st' alt="Logout" />
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </nav>
//       </div>
//     </div>
//   );
// }
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateLoginStatus } from './userdetails';
import logo from '../images/logo6.png'; // Import your logo image
import logout from '../images/logout.png';
import './AfterLoginheader.css';
import axios from 'axios';
import { baseurl } from './Baseurl';
export default function Header() {
  const userData = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();

  // Update login status if Pagename is undefined
  
  useEffect(() => {
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: userData.Pagename,
        Token:userData.Token,
        userId:userData.userId,
      })
    );
  }, []);
  console.log("userName",userData.Token)
  const Logout = () => {
    axios
    .get(baseurl +'/ldc/logout/'+userData.userId, {
     
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      
    })
    .catch((error) => {
      console.error(error);
    });
  }
  return (
    <div className="headersizediv1">
      <div className='headercolor'>
        <div className="logo-inside">
          <img src={logo} alt="Logo" className="logo-image"  />
        </div>
        <div className="navbar-center">
          <button className='btn-style' disabled>
            <b>{userData.Pagename}</b>
          </button>
        </div>
        <div className="navbar-right">
          <div className='namest'>
            <b>&nbsp;{userData.name}</b>
            <br />
            <span>{userData.role}</span>
          </div>
          <a href="/">
            <img src={logout} onClick = {Logout} className='icon1st' alt="Logout" />
          </a>
        </div>
      </div>
    </div>
  );
}

