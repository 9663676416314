// //import React, { useState, useEffect,useSelector} from 'react'
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import LoginPage from './component/loginpage'
// import Stafflogin from './component/stafflogin'
// import { useSelector } from 'react-redux'
// import Sidebar from './component/sidebar'
// import Header from './component/Afterloginheader'
// import Loginform from './component/loginform'


// function RoutePage() {
//   const isLoggedIn = useSelector((state) => state.userDetails.isLoggedIn)
//   return (
   
      
//       /* {isLoggedIn ? <LoginPage /> : ""}
//           {isLoggedIn ? <App />:"" } */
//       <Router>
//          {alert("login status",isLoggedIn)}   
//           {isLoggedIn ? <Header /> : ''} 
//         {isLoggedIn ? <Sidebar /> : ''} 
     
       
        
//         <Routes>

//           <Route path="/"  element={<LoginPage/>} /> 
//           <Route path="/header"  element={<Header />} /> 
//           <Route path="/Loginform"  element={<Loginform/>} /> 
//           <Route path="/Stafflogin"  element={<Stafflogin/>} />
//           <Route path="/sidebar"  element={<Sidebar/>} />
//         </Routes>
//       </Router> 
  
//   );
// }

// export default RoutePage;


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginPage from './component/loginpage';
import Stafflogin from './component/stafflogin';
import Sidebar from './component/sidebar';
import Header from './component/Afterloginheader';
import Loginform from './component/loginform';
import Resetpassword from './component/resetpassword';
import ActionTaken from './component/actiontaken';
import History from './component/studenthistory';
import EveningRoaming from './component/eveningroaming/eveningroaming'
import RoamingCampus from './component/roamingcampus/roamingcampus'
import Hod from './component/hodlogin'
import Viewcomplaint from './component/viewcomplaint';
function RoutePage() {
  const isLoggedIn = useSelector((state) => state.userDetails.isLoggedIn);

  return (
    <Router>
      {isLoggedIn ? <Header /> : ''}
      {isLoggedIn ? <Sidebar /> : ''}
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/header" element={<Header />} />
        <Route path="/Loginform" element={<Loginform />} />
        <Route path="/Stafflogin" element={<Stafflogin />} />
        <Route path="/sidebar" element={<Sidebar />} />
        <Route path="/Resetpassword" element={<Resetpassword />} />
        <Route path="/ActionTaken" element={<ActionTaken />} />
        <Route path="/studentHistory" element={<History />} />
        <Route path="/EveningRoaming" element={<EveningRoaming />} />
        <Route path="/RoamingCampus" element={<RoamingCampus />} />
        <Route path="/Hod" element={<Hod/>} />
        <Route path="/Viewcomplaint" element={<Viewcomplaint/>} />
      </Routes>
    </Router>
  );
}

export default RoutePage;
