import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { updateLoginStatus } from './userdetails';
import './Sidebar.css';

const Sidebar = () => {
    const [isMenu1Open, setIsMenu1Open] = useState(false);
    const[successstatus,setsuccessstatus]=useState(false);
    const[successstatus1,setsuccessstatus1]=useState(false);
    const[successstatus2,setsuccessstatus2]=useState(false);
    
    const[successstatus3,setsuccessstatus3]=useState(false);
    const [isVisible1, setIsVisible1] = useState(true);
    const [isVisible2, setIsVisible2] = useState(true);
    const [isVisible3, setIsVisible3] = useState(true);
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userDetails);


    const toggleMenu1 = () => {
        setIsMenu1Open(!isMenu1Open);
        
        
      };

      const toggleVisibility = () => {
        if(userData.Pagename === "Staff Login")
          {
            alert(userData.Pagename)
            setIsVisible1(!isVisible1)
            setIsVisible2(!isVisible2)
            setIsVisible3(!isVisible3)
         
         
          }
     
        else if(userData.Pagename === "Convenor Login")
        {
          alert(userData.Pagename)
          setIsVisible2(!isVisible2)
       
   
        }
        else{
          alert(userData.Pagename)
          setIsVisible1(!isVisible1)
          
        }
      };
      useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        toggleVisibility();
        dispatch(
          updateLoginStatus({
              isLoggedIn:true,
              Pagename: userData.Pagename, 
              Token:userData.Token,
              userId:userData.userId,
                  
          })
        )
      
    }, [])
      const HandleActionTaken = () => 
        {
             setsuccessstatus3(true);
        }
        
        const Handleeveningroaming = () => 
          {
               setsuccessstatus1(true);
          }

          const Handleroamingcampus = () => 
            {
                 setsuccessstatus2(true);
            }

        
      // const HandleStudentHistory = () => 
      //   {
      //     setsuccessstatus1(true);
      //   }
      
      function updatelinkname(lname){
    
       
    setsuccessstatus(true)
           } 
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [visibility, setVisibility] = useState({
        viewComplaint: true,
        actionTaken: true,
        studentHistory: true,
    });

  

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

    

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        toggleVisibility();
        dispatch(updateLoginStatus({ isLoggedIn: true, Pagename: userData.Pagename,Token:userData.Token,
            userId:userData.userId,}));
    }, [userData.Pagename, dispatch]);

    return (
        <><div>
            <div className={`sidebar1 ${isSidebarOpen ? 'mobile open' : 'mobile'}`}>
                <div className="sidebar-item3">
                    <b onClick={toggleMenu}>Raise complaint</b>
                </div>
                {isMenuOpen && (
                    <div className="submenu2">
                        <div>
                            <button className="sub2 mb-2">
                                <Link to="/Stafflogin"><b>Late comer</b></Link>
                            </button>
                        </div>
                        <div>
                            <button className="sub2 mb-2">
                                <Link to="/EveningRoaming"><b>Evening Roaming</b></Link>
                            </button>
                        </div>
                        <div>
                            <button className="sub2 mb-2">
                                <Link to="/RoamingCampus"><b>Roaming Campus</b></Link>
                            </button>
                        </div>
                    </div>
                )}
                {visibility.viewComplaint && (
                    <div className="sidebar-item2">
                        <button className="sidebar-item2">
                            <Link to="/Viewcomplaint"><b>View Complaint</b></Link>
                        </button>
                    </div>
                )}
                {visibility.actionTaken && (
                    <div className="sidebar-item2">
                        <button className="sidebar-item2">
                            <Link to="/Hod"><b>View Complaint for Taking Action</b></Link>
                        </button>
                    </div>
                )}
                <div>
                    <button className="sidebar-item2">
                        <Link to="/ActionTaken"><b>Action Taken</b></Link>
                    </button>
                </div>
                {visibility.studentHistory && (
                    <div className="sidebar-item2">
                        <button className="sidebar-item2">
                            <Link to="/studentHistory"><b>Student History</b></Link>
                        </button>
                    </div>
                )}
            </div>
            <div className="hamburger" onClick={toggleSidebar}>
                ☰
            </div>
            <div className="sidebar">
{/*    
       {successstatus && <ButtonLink btnPath="/Stafflogin" />}
       {successstatus1 && <ButtonLink btnPath="/EveningRoaming" />}
       {successstatus2 && <ButtonLink btnPath="/RoamingCampus" />}
       {successstatus3 && <ButtonLink btnPath="/ActionTaken" />} */}
           {/* {successstatus && <ButtonLink btnPath="/ActionTaken" />} */}
      <div className="sidebar-item1"><b onClick={toggleMenu1}>Raise complaint</b></div>
          {isMenu1Open && (
            <div className="submenu">
              	{/* <Link to="/stafflogin" className='sub' onClick={()=>updatelinkname('stafflogin')}>Late comer</Link> */}
                {/* <button className='sub' onClick={()=>updatelinkname('Stafflogin')}> <h4>Late comer</h4></button> 
                <button className='sub' onClick={Handleeveningroaming}> <h4>Evening roaming</h4></button> 
                <button className='sub' onClick={Handleroamingcampus}> <h4>Roaming campus</h4></button> 
         */}
          {/* <h4 className='sub'>Evening roaming</h4>
          <h4 className='sub'>Roaming campus</h4> */}
          <>
                 <div> <button  className="sub mb-2"><Link to="/Stafflogin">
                        <b>Late comer</b>
                    </Link></button></div>  
                    <div> <button  className="sub mb-2"><Link to="/EveningRoaming">
                       <b>Evening Roaming</b> 
                    </Link></button></div>  
                    <div> <button  className="sub mb-2"><Link to="/RoamingCampus">
                        <b>Roaming Campus</b>
                    </Link></button></div> 
                  
                </>
            </div>
          )}
        {isVisible1 && <div className="sidebar-item" >
          <button  className="sidebar-item"> <Link to="/Viewcomplaint" >
        <b>view complaint</b></Link> </button></div>}
        
      {isVisible2 && <div className="sidebar-item" 
      // onClick={HandleStudentHistory}
      ><button  className="sidebar-item"> <Link to="/Hod" >
        <b>view complaint for taking action</b>
         </Link> </button></div>}
       
       <div><button  className="sidebar-item"> <Link to="/ActionTaken" >
         <b>Action Taken</b>
     </Link> </button> </div>
        
        {isVisible3 && <div className="sidebar-item" 
      // onClick={HandleStudentHistory}
      ><button  className="sidebar-item"> <Link to="/studentHistory" >
        <b>student history</b> 
        </Link> </button></div>}
    </div>
    </div>
        </>
       
    );
};

export default Sidebar;
