import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ButtonLink(btnDetails) {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(btnDetails.btnPath, {
      state: {
        // sub: btnDetails.sub,
        // examtopic: btnDetails.examtopic,
      
       
      },
    })
  }, [
    navigate,
     btnDetails.btnPath,
    // btnDetails.sub,
    // btnDetails.examtopic,
   
  ])

  return null
}
