import React, { useState, useEffect } from 'react';
// import './resetpassword.css'

const Resetpassword = () => {
    const [username, setUsername] = useState('')
    const [usernameValid, setusernameValid] = useState(false)
    const [usernameerror, setusernameerror] = useState('')
    const [password, setPassword] = useState('')
    const [passwordValid, setPasswordValid] = useState(false)
    const [passworderror, setPassworderror] = useState('')


    function onepasswordChange(e) {

        setUsername(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
      }
    
    
      function onpasswordChange(e) {
    
        setPassword(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
    
    
      }
    const validateField = (fieldName, value) => {
    if (value.length == 0) {
      setPassworderror(
        'Password should be more than or equal to 6 character and it should contain lowercase,uppercase,numeric and special character'
      )
      setPasswordValid(false)
    } else {
      setPassworderror('')
      setPasswordValid(true)
    }

    if (fieldName === 'username') {

      if (value.length != 0) {
        setusernameerror('')
        setusernameValid(true)
      } else {


        setusernameerror(
          'Please Enter your Name'
        )
        setusernameValid(false)
      }
    }
  }
    return (
      <div className="login-page">
    
         {/* <div class="logo">
         <img  src={logo}  alt="Logo" style={{ width: 690, height: 90 }} />
         </div>
         */}
        <form className="login-form">
          <h1>Reset password</h1>
         
         
          <div className="row pb-3 pt-3"> 
          <div className="  col-lg-12 col-md-11 ">
          <center>
            <label htmlFor="username">faculty id :</label>
            
            <input
              
              type="text"
              id="username"
              classname="login-box3"
            //   value={username}
            //   onChange={handleUsernameChange}
            //  //required
            />
           </center> 
             </div>
           
            
            <div className=" col-lg-10 col-md-9 ">
            <center>
              <label htmlFor="department">department :</label>
               <input
                     type="text"
                     id="department"
                     classname="login-box3"
  
               />
           </center>  
            </div>
            
            <div className=" col-lg-12 col-md-11">
            <center> 
              <label htmlFor="phone number">phone number :</label>
               <input
                     type="phone number"
                     id="phone number"
                     classname="login-box3"
  
               />
            </center>  
            </div>
            
            <div className="col-lg-12 col-md-11">
            <center>
              <label htmlFor="mail id">mail id :</label>
               <input
                     type="mail"
                     id="username"
                     classname="login-box3"
  
               />
           </center>
            </div>
          
           <div className="col-lg-12 col-md-11">
           <center>
            <label htmlFor="password">New Password:</label>
            <input
           
            // handleInputChange={onpasswordChange}
              type="password"
              id="password"
              maxLen={16}
              classname="login-box3"
              value={password}
              onChange={onpasswordChange}
            //   //required
            />
      </center>
          </div>
          
          <div className=" col-lg-12 col-md-11">
          <center> 
            <label htmlFor="password"> Confirm Password:</label>
            <input
           
            handleInputChange={onpasswordChange}
              type="password"
              id="password"
              maxLen={16}
              classname="login-box3"
            />
            </center>  
          </div>
          <div className=" col-lg-3 col-md-3 ">
            <div className='reset'><button type="submit" > Reset password </button> </div>
            </div>
          </div>                            
       </form>
        
      </div>
    );
  };
  
  export default Resetpassword;
  