import './App.css';
import Route from './route'
import Sidebar from './component/sidebar'
import Header from './component/Afterloginheader';
import Conviner from './component/viewcomplaint';
import Loginform from './component/loginform'
import LoginPage from './component/loginpage';
import Action from './component/actiontaken';
import History from './component/studenthistory';
import Stafflogin from './component/stafflogin';
import Hod from'./component/hodlogin';
import Modal from './component/Modal';
import  Viewcomplaint from './component/viewcomplaint';
function App() {
  return (
    <div className='overalllogin' >
 
        <Route /> 
        {/* <Action/> 
        <History/>
        <Stafflogin/>
        <Conviner/>
       
        <Modal/> */}
         {/* <Hod/> */}
         {/* < Viewcomplaint/> */}
         {/* <Action/>  */}
    </div>
  );
}

export default App;




