// import React, { useState, useEffect } from 'react';
// import { connect, useDispatch, useSelector } from 'react-redux'
// import './LoginPage.css'; 
// import { Link } from 'react-router-dom';
// import Stafflogin from './stafflogin';

// import ButtonLink from './buttonlink'; 
// import logo from '../images/logo.jpg';

// import {
//   updateLoginStatus,
  
// } from './userdetails';



// const LoginPage = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const dispatch = useDispatch()
//   const[successstatus,setsuccessstatus]=useState(false);
//   const userData = useSelector((state) => state.userDetails)

//   useEffect(() => {
//     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
//     if (successstatus) {
   
//       dispatch(
//         updateLoginStatus({
//             isLoggedIn:true,
            
                
//         }))
//     }
//   }, [successstatus])
//   const handleUsernameChange = (event) => {
//     setUsername(event.target.value);
//   };

//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault(); // Prevent default form submission
//     alert("button clicked")
   
//      console.log('Username:', username);
//      console.log('Password:', password);
     
//      if(username==="admin" && password==="1234")
//      {
//         let userData = new FormData()
//         userData.append('username', username)
//         userData.append('password', password)
//         setsuccessstatus(true);
//         setUsername('');
//         setPassword('');

//      }
//      else{
//       alert("invalid user")
//      }

//     // // Reset the form fields after submission (optional)
//     // setUsername('');
//     // setPassword('');
  
   
//   };
 


//   const handleForgotPassword = () => {
//     // Implement forgot password logic here
//     console.log('Forgot Password clicked');
//   };

//   const handleSignUp = () => {
//     // Implement sign up navigation or logic here
//     console.log('Sign Up clicked');
//   };

//   return (
//     <div className="login-page">
//        {successstatus && <ButtonLink btnPath="/Stafflogin" />}
//        <div class="logo">
//        <img  src={logo}  alt="Logo" style={{ width: 690, height: 90 }} />
//        </div>
      
//       <form className="login-form" onSubmit={handleSubmit}>
//         <h1>Discipline Duty</h1>
//        <h2>Login Page</h2>
//         <div className="form-group ">

//           <label htmlFor="username">Username:</label>
//           <input
            
//             type="text"
//             id="username"
//             className="inputfield"
//             value={username}
//             onChange={handleUsernameChange}
//            //required
//           />
//         </div>
//         <div className="form-group ">
//           <label htmlFor="password">Password:</label>
//           <input
//           className="inputfield"
//             type="password"
//             id="password"
//             value={password}
//             onChange={handlePasswordChange}
//             //required
//           />
//           <div className="additional-links">
//         <a>Forgot Password?</a>
//         <span> | </span>
//         <a > <Link to="/Loginform">Sign Up</Link></a>
//       </div>
//         </div>
        
          
//         <nav>
//         <button type="submit" onClick={handleSubmit}> Login </button> 
         
//         </nav>
    
//       </form>
//       <div>
//             <h1>Login Page</h1>
//             <Link to="/Stafflogin">Go to Staff Login</Link>
//       </div>
      
//     </div>
//   );
// };

// export default LoginPage;


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './LoginPage.css';
import axios from 'axios';
import { baseurl } from './Baseurl';
import logo from '../images/logo.jpg';
import { updateLoginStatus } from './userdetails';
import ButtonLink from './buttonlink';
import './LoginPage.css';


const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const [errorstatus, setErrorstatus] = useState(false);
  const [successstatus, setsuccessstatus] = useState(false);
  const [getLogin, setGetLogin] = useState("");
  const [logginuser, setLogginuser] = useState("");
  const [Token, setToken] = useState("");
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (successstatus) {
      dispatch(
        updateLoginStatus({
          isLoggedIn: true,
          Pagename: getLogin,
          Token:Token,
          userId:logginuser,
        })
      );
    }
  }, [successstatus, dispatch]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   if (username === 'admin' && password === '1234') {
  //     setsuccessstatus(true);
  //     setUsername('');
  //     setPassword('');
  //   } else {
  //     alert('Invalid user');
  //   }
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('username:', username);
    console.log('password:', password);
   
    
      if(username && password)
      {
        
    let userData1 = new FormData()
    userData1.append('facultyId', username)
    userData1.append('password', password)



    axios
      .post(baseurl +'/Staff/Login', userData1, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      .then((response) => {
       
  
        console.log(response.data) 

        const data = response.data
        console.log(data.FacultyId)
        console.log(data.token)
        setLogginuser(data.FacultyId)
        setToken(data.token)

        setGetLogin(data.message)
        setsuccessstatus(true)

      })

      .catch((error) => {

        console.log(error)
        setErrorstatus(true)

      })
      
    }
   
}

  return (
    <div className="login-page">
      {successstatus && <ButtonLink btnPath="/Stafflogin" />}
      
      <form className="login-form" onSubmit={handleSubmit}>
        {/* <div className="logo">
         <img src={logo} alt="Logo"  />
        </div> */}
        <h1>Discipline Duty</h1>
        <h2>Login Page</h2>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            className="inputfield"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            className="inputfield"
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <div className="additional-links">
          <Link to="/Resetpassword">Forgot password</Link>
            <span> | </span>
            <Link to="/Loginform">Sign Up</Link>
          </div>
        </div>
        <nav>
          <div className='loginbutton'> <button type="submit">Login</button></div>
          {errorstatus && <div className="wrongmsg">Invalid username or password</div>}

        </nav>
      </form>
      
    </div>
  );
};

export default LoginPage;
